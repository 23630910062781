import {
  MINT_100,
  p22MackinacBookMixin,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import styled from 'styled-components';

const customMobileBreakpoint = 870;

export const Section = styled.section`
  background-color: white;
`;

export const Content = styled.div`
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6, 0)};
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10, 0)};
  }
`;

export const Headline = styled.h2`
  font-size: 40px;
  line-height: 48px;
  ${p22MackinacBookMixin}
  font-weight: 350;
  margin: 0;
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4)}px;
  max-width: 320px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    font-size: 48px;
    line-height: 56px;
    margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6)}px;
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(705)} {
    max-width: 100%;
  }
`;

export const OnboardingItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4)}px;
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4)}px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    flex-direction: row;
    margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5)}px;
  }
`;

export const DottedLine = styled.div`
  position: absolute;
  top: 30px;
  left: 30px;
  width: 1px;
  height: 85%;
  background-image: linear-gradient(${MINT_100} 50%, rgba(255, 255, 255, 0) 0%);
  background-position: left;
  background-size: 1px 20px;
  background-repeat: repeat-y;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    background-image: linear-gradient(to right, ${MINT_100} 50%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 20px 1px;
    background-repeat: repeat-x;
    width: 72%;
    height: 1px;
    top: 30px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
`;
const Bullet = styled.div`
  width: 12px;
  height: 12px;
  position: absolute;
  background-color: ${MINT_100};
  border-radius: 50%;
`;
export const BulletLeft = styled(Bullet)`
  top: 15%;
  left: 25px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    top: 25px;
    left: 25%;
  }
`;
export const BulleRight = styled(Bullet)`
  top: 73%;
  left: 25px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    top: 25px;
    right: 25%;
    left: auto;
  }
`;
export const BulleMiddle = styled(Bullet)`
  top: 43%;
  left: 25px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    top: 25px;
    right: 50%;
    left: auto;
  }
`;

export const OnboardingItem = styled.div`
  display: flex;
  align-items: flex-start;
  text-align: left;
  gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: 30%;
  }
`;

export const ImageWrapper = styled.div`
  max-width: 64px;
  z-index: 1;
  background-color: white;
`;

export const OnboardingHeadline = styled.h3`
  font-size: 20px;
  line-height: 28px;
  margin: 0;
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const OnboardingSubHeadline = styled.h4`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  margin: 0;
  font-weight: 400;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    max-width: 340px;
  }
`;
