import { PropsWithTheme, WHITE_100 } from '@rategravity/own-up-component-library';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

export const CardsWrapper = styled.div`
  padding-top: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(4)}px`};
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(12)}px`};
`;

export const Card = styled.div<{ boxShadow?: string }>`
  border-radius: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(2)}px`};
  box-shadow: ${(props) =>
    props?.boxShadow || '0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -2px rgb(0 0 0 / 5%)'};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const StyledImage = styled(GatsbyImage)`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    height: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(26.5)}px`};
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    height: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(24)}px`};
  }
`;

export const Tile = styled.div<{ backgroundColor?: string; padding?: string }>`
  background-color: ${(props) => props.backgroundColor || WHITE_100};
  flex-grow: 1;
  padding: ${(props) => props.padding || '32px 24px'};
  cursor: pointer;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(1)}px`};
`;
