import {
  ALOE_100,
  OwnUpTextInput,
  OwnUpUnderlineLink,
  p22MackinacBookMixin,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import styled from 'styled-components';

const customMobileBreakpoint = 870;

export const Section = styled.section`
  background-color: ${ALOE_100};
`;

export const Content = styled.div`
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(8, 0)};
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10, 0)};
  }
  display: grid;
  row-gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5)}px;
`;

export const SectionTitle = styled.h2`
  ${p22MackinacBookMixin};
  font-size: 40px;
  line-height: 48px;
  margin: 0 auto;
  color: white;
`;

export const FormIconWrapper = styled.div`
  margin: 0 auto;
`;

export const FormInput = styled(OwnUpTextInput)`
  /* width: 100%; */
`;

export const FromInputsGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  width: 100%;
  margin: auto;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    grid-template-columns: 1fr 1fr;
    max-width: 580px;
  }
`;

export const LendersEmail = styled(OwnUpUnderlineLink)`
  margin: 0 auto;
`;
