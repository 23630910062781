import { p22MackinacBookMixin, PropsWithTheme } from '@rategravity/own-up-component-library';
import styled from 'styled-components';

const customMobileBreakpoint = 870;

export const Content = styled.div`
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(8, 0)};
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10, 0)};
  }
  display: grid;
  row-gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6)}px;
`;

export const SectionTitle = styled.h2`
  ${p22MackinacBookMixin};
  font-size: 40px;
  line-height: 48px;
  margin: 0;
`;
