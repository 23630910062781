export const mockData = {
  pinnedPosts: [
    {
      title: 'Questions To Ask When Buying A House',
      description:
        'The key questions you should ask your real estate agent, the seller or their agent, your home inspector and your mortgage lender before buying a home.',
      mainImage: {
        asset: {
          gatsbyImageData: {
            images: {
              sources: [],
              fallback: {
                src: 'https://cdn.sanity.io/images/paxrx6m7/staging/c903c8d2366cd214a6a7fcbaa96388eae484768f-675x450.png?rect=0,1,675,449&w=410&h=273&auto=format',
                srcSet:
                  'https://cdn.sanity.io/images/paxrx6m7/staging/c903c8d2366cd214a6a7fcbaa96388eae484768f-675x450.png?rect=0,1,675,449&w=320&h=213&auto=format 320w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/c903c8d2366cd214a6a7fcbaa96388eae484768f-675x450.png?rect=0,1,675,449&w=410&h=273&auto=format 410w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/c903c8d2366cd214a6a7fcbaa96388eae484768f-675x450.png?rect=0,1,675,449&w=654&h=435&auto=format 654w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/c903c8d2366cd214a6a7fcbaa96388eae484768f-675x450.png?rect=0,1,675,449&w=675&h=449&auto=format 675w',
                sizes: '(min-width: 410px) 410px, 100vw'
              }
            },
            layout: 'constrained',
            width: 410,
            height: 273,
            placeholder: {
              fallback:
                'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAADGElEQVQokRXB7W8SdwAA4EsRei9wvHhg4SiwFuta+wKtUOACtB6UK3f8uDuOwkFbBJVa6pxgWyUbSrUIri3RuUkz41azxRjNtmROY/zi9lG/+C8ZnweacQ15pr+enjrptJtIE+4giTPu0Zws1r+r3253bu7ublzZyMppKZ1MSYAXOV7kxBRI8izLxSC/dywaCfo9LtsJPYGrBgzIKYdRYudbOzd/fNi7d/Cgtrkp55bEVEKUgCgBOZ/JFVZYXgjRC9D83KwggNmZKZMW1aMKs041YdcvnXVXS9nK+VKpUFhdliUpyQssL7ByPlOr17d22lKxQsVSkCynM5nMxCmnHlXqEIVV30+dNK7OTxTZEDtHRUMBjqEBYACIAcBk80uNu53DZ3+3fj6q1NtQtVqTUukhK4n3K7RI3wCu9A0ZcsHTcsTPhHyxMAUYOsFG4ywdj9NJPn7txvbLf9/+9/HT6/8/QJc3rsZjjN1s1CF9eqTPqFa4HIb0nHsZ0JlkrJgRN84VzslLIB6haSoaDZZKK/vdvecvnr959xYqX6rR4bB9QEdgfQSmIHWq2TFbMb1YrRQ3v1lrbm8d7LZbjVvfXlrLZaUEF01wkWRyUZL48sUCdH7tqs/jthKIUaMwaY4NW7SJSKhRv9496O539g46+737D3/76dHR46e93pOtak0ATIDyjLunJ874ISGdH3U6TuBKk1Zp1CgdpFEQpL37vxw9e9U7/KPT7nYa3z+60/zzxT9/vXq/09hJi1xoznfa5Roe90D0Ame3mI9jSgJX6dUqwqCfmgmslreae7/+8OBp81Zr+2Lh9pX1J49/3+8eFpaXBZ6JMeEZj3tschKKLvKDZguOKLVqWI3CGo3GYDTbnZOBSCp/YXO9fLmSTV1fLzcbrZSY9fs8icQCy0YClCdAeSEgrQza7CisQhEERhBMrUEwdT+s1hGWkXGv1xsO+YM8CxJx3jk8YrNZKe80HfAGA95o7Cwk5kqDXw3BXyAwjKCYGkYxVT8CwxhOkAZy9LhlxGobJi12DMNRFBs0GKZIknK5Fjn2M0L52PeOuz2rAAAAAElFTkSuQmCC'
            }
          }
        },
        alt: ''
      },
      categories: [
        {
          title: 'First Home Loan'
        }
      ],
      slug: {
        current: 'questions-to-ask-when-buying-a-house'
      }
    },
    {
      title: 'Buying a House for Sale by Owner (FSBO)?',
      description:
        "Some sellers choose the “for sale by owner”, or FSBO, to save money. Here's what you need to know if you are buying a house for sale by owner (FSBO).",
      mainImage: {
        asset: {
          gatsbyImageData: {
            images: {
              sources: [],
              fallback: {
                src: 'https://cdn.sanity.io/images/paxrx6m7/staging/55dad4b1565f9ea1c906ffcd6b6ea59dfc49d3cc-1120x651.jpg?rect=0,1,1120,650&w=410&h=238&auto=format',
                srcSet:
                  'https://cdn.sanity.io/images/paxrx6m7/staging/55dad4b1565f9ea1c906ffcd6b6ea59dfc49d3cc-1120x651.jpg?w=320&h=186&auto=format 320w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/55dad4b1565f9ea1c906ffcd6b6ea59dfc49d3cc-1120x651.jpg?rect=0,1,1120,650&w=410&h=238&auto=format 410w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/55dad4b1565f9ea1c906ffcd6b6ea59dfc49d3cc-1120x651.jpg?w=654&h=380&auto=format 654w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/55dad4b1565f9ea1c906ffcd6b6ea59dfc49d3cc-1120x651.jpg?rect=0,1,1120,650&w=768&h=446&auto=format 768w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/55dad4b1565f9ea1c906ffcd6b6ea59dfc49d3cc-1120x651.jpg?rect=0,1,1120,650&w=1024&h=594&auto=format 1024w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/55dad4b1565f9ea1c906ffcd6b6ea59dfc49d3cc-1120x651.jpg?rect=0,1,1120,650&w=1120&h=650&auto=format 1120w',
                sizes: '(min-width: 410px) 410px, 100vw'
              }
            },
            layout: 'constrained',
            width: 410,
            height: 238,
            placeholder: {
              fallback:
                'data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAMABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAQL/xAAhEAABBAICAgMAAAAAAAAAAAABAgMEEQAFBhMSIVFhgf/EABcBAAMBAAAAAAAAAAAAAAAAAAABAwX/xAAbEQEAAgIDAAAAAAAAAAAAAAABAAIDEQQSFP/aAAwDAQACEQMRAD8AR43D3O9dmdj78ZtCgtoKNfgvGy5tn+Tr2MlqT1xYpjpTdhxVVdZfKlKc9kgfA9ZzNlusxfFCqGY5zr1ACLsJoJvH+dxYWrbY2WtkmSiwogYZFEp03ZBP2MMv7skNk//Z'
            }
          }
        },
        alt: 'a blue and white house with a red "For Sale by Owner" sign in the grass'
      },
      categories: [
        {
          title: 'First Home Loan'
        }
      ],
      slug: {
        current: 'fsbo'
      }
    }
  ],
  lastPosts: [
    {
      slug: {
        current: 'first-time-homebuyer-these-10-tips-are-for-you'
      },
      title: 'First-Time Homebuyer? These 10 Tips Are For You',
      description:
        'The homebuying process can be long, repetitive, and downright confusing. Here are 10 tips to help you navigate the real estate process.',
      _rawExcerpt: [
        {
          _key: '6ccc02d4adfa',
          _type: 'block',
          children: [
            {
              _key: '5be8ea7c432a0',
              _type: 'span',
              marks: [],
              text: 'The homebuying process can be long, repetitive, and downright confusing. Here are 10 tips to help you navigate the real estate process. | Own Up'
            }
          ],
          markDefs: [],
          style: 'normal'
        },
        {
          _key: 'ccaa8c7f429e',
          _type: 'block',
          children: [
            {
              _key: '7990cd9dda0d0',
              _type: 'span',
              marks: [],
              text: '\n'
            }
          ],
          markDefs: [],
          style: 'normal'
        }
      ],
      categories: [
        {
          title: 'First Home Loan'
        }
      ],
      mainImage: {
        asset: {
          gatsbyImageData: {
            images: {
              sources: [],
              fallback: {
                src: 'https://cdn.sanity.io/images/paxrx6m7/staging/7e0b7beb7ac1f05830d777c5b341e9c0235baf0b-1120x780.jpg?rect=1,0,1118,780&w=410&h=286&auto=format',
                srcSet:
                  'https://cdn.sanity.io/images/paxrx6m7/staging/7e0b7beb7ac1f05830d777c5b341e9c0235baf0b-1120x780.jpg?rect=1,0,1119,780&w=320&h=223&auto=format 320w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/7e0b7beb7ac1f05830d777c5b341e9c0235baf0b-1120x780.jpg?rect=1,0,1118,780&w=410&h=286&auto=format 410w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/7e0b7beb7ac1f05830d777c5b341e9c0235baf0b-1120x780.jpg?rect=1,0,1119,780&w=654&h=456&auto=format 654w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/7e0b7beb7ac1f05830d777c5b341e9c0235baf0b-1120x780.jpg?rect=1,0,1118,780&w=768&h=536&auto=format 768w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/7e0b7beb7ac1f05830d777c5b341e9c0235baf0b-1120x780.jpg?rect=1,0,1119,780&w=1024&h=714&auto=format 1024w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/7e0b7beb7ac1f05830d777c5b341e9c0235baf0b-1120x780.jpg?rect=1,0,1119,780&w=1120&h=781&auto=format 1120w',
                sizes: '(min-width: 410px) 410px, 100vw'
              }
            },
            layout: 'constrained',
            width: 410,
            height: 286,
            placeholder: {
              fallback:
                'data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAOABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAYHBf/EACQQAAEDAwMEAwAAAAAAAAAAAAECAwQABREGEiETMUFRBxVh/8QAFQEBAQAAAAAAAAAAAAAAAAAAAwT/xAAbEQACAgMBAAAAAAAAAAAAAAABAgARAyFREv/aAAwDAQACEQMRAD8AbJbjdj0MiW80FrW8FbBzx4JHqlm0TIk2/wARTMp1x911I6KU4AOe+PAqmfUW+ex0pjG/IGPQA/KwLnaLZbXUTLVFDM5twYdPqiT0i1yO4GVr7In8l6fvDGs7iDDcWlS96VJGQQe1FUjVRen3XrSpTqXdgThsccZoqYncrA1P/9k='
            }
          }
        },
        alt: 'Two people looking at houses on a laptop'
      }
    },
    {
      slug: {
        current: 'mortgage-comparison-first-time-homebuyers'
      },
      title: 'A Mortgage Comparison for First Time Home Buyers',
      description: 'You won’t be a rookie for long!',
      _rawExcerpt: [
        {
          _key: '037b8b7d1e11',
          _type: 'block',
          children: [
            {
              _key: '78805ceba320',
              _type: 'span',
              marks: [],
              text: 'By comparing the different types of mortgages and understanding the associated costs and fees, you can make an informed choice that works for your unique situation. | Own Up'
            }
          ],
          markDefs: [],
          style: 'normal'
        }
      ],
      categories: [
        {
          title: 'First Home Loan'
        }
      ],
      mainImage: {
        asset: {
          gatsbyImageData: {
            images: {
              sources: [],
              fallback: {
                src: 'https://cdn.sanity.io/images/paxrx6m7/staging/0684aea95888f61c2b8d9c83c7da7604e07a7c2d-750x500.png?rect=0,1,750,499&w=410&h=273&auto=format',
                srcSet:
                  'https://cdn.sanity.io/images/paxrx6m7/staging/0684aea95888f61c2b8d9c83c7da7604e07a7c2d-750x500.png?rect=0,1,750,499&w=320&h=213&auto=format 320w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/0684aea95888f61c2b8d9c83c7da7604e07a7c2d-750x500.png?rect=0,1,750,499&w=410&h=273&auto=format 410w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/0684aea95888f61c2b8d9c83c7da7604e07a7c2d-750x500.png?rect=0,1,750,499&w=654&h=435&auto=format 654w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/0684aea95888f61c2b8d9c83c7da7604e07a7c2d-750x500.png?rect=0,1,750,499&w=750&h=499&auto=format 750w',
                sizes: '(min-width: 410px) 410px, 100vw'
              }
            },
            layout: 'constrained',
            width: 410,
            height: 273,
            placeholder: {
              fallback:
                'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAADJElEQVQokQEZA+b8ALXCzrbCzLG+yau3xqy1w6mutYeIh2NhX19cWGllYW5qZ3p4eY6OkZGOj5mSjKuhl66ilaufkKieiKWbfwCvus2xu8yxu8yuuMusuc6vv9KcrL1+iZZ8foSEgoWHhYmNjZKXmJ+amZ6in5+vqaSvqKGsqJyvrpeyspAAqbTJqbPJq7XKrbfNqbnSn7raja/UgJy8i5epmJumnJ2nnaCpoKKroqOrqamusrGvsbOrs7ipub+nu8GhAKewxaKsw6Wvx7fA1cDL3avB3Ier03qdxYedu5ukuKapt6eqtqips6iqsq2vsrK1rLO5pba9o7nAornAnwCNmK6Ekaqbpr7N0uLl5u/W3Ouovt2BpcyAn8WWp8GmrL6rrr2ssLuusrewtbGxtqWwtpuvtZaus5KusY4AWml+YnGGoKm64eDr8u307Onxz9bpobvckK3PkqO7k5qsmp6toaavpKuppKuhoqeXn6SSmp+NlZeEk5J7AEdQXWFndK+wuuTe5uHa4eDZ4OPg6dHZ6bfD1IyUoWlteWhrdm90eHJ3dW91b2tuZ2ptaWhra2xsaXZxZwBNTldlZW2pqLDPztfHxdDIx9Da2ODf4ejCx89+gopCR081OUA2O0E4PEE2Oj42ODw5PEE9QUhWVlx+eHgAYF9pdnV9qau1xMnTvsDNvcDMy8/Z19ziw8bMe36GO0BJKi84Ky84LDA5LjI6MTQ9NDhANjpCT1FXgX6DAJyap62rt87M19va48nH0cPDztbV3uLh5tDN0Y+OlU1QWjc7RjM5QzE3QTI3QjQ5RDQ4QDI0ODw8P1dWWgDDwc3Ny9jh3unq5u/d1t/X0trm4efk3+TPycyppaxvc4BKVmc8SVszPU0yO0s1Pk41PEkyNj4zNTk5O0AAi4ePlJGaqqixxsPM1tHZ3djf5uLq4NvjzsjNvrnAlJelaHSJWml/V2J3V2F1XGV5XmZ4UldlOj1ILzI7ADs2OTk0OElFSXNwdp6aore0vM7M1uDd6eLf6NjT3bSyv5WYqZqfsaWpu6SnuKSmt6Wnt35+izk4PxwaHRADzOfJQ8f+AAAAAElFTkSuQmCC'
            }
          }
        },
        alt: 'a toy house next to a set of keys and a calculator'
      }
    },
    {
      slug: {
        current: 'can-you-back-out-of-a-house-offer'
      },
      title: 'Executed Contract: When & How to Walk Away',
      description:
        "You're under contract on a house, but you've run into some snags along the way. Can you walk away from the deal?",
      _rawExcerpt: [
        {
          _key: 'ccd7c92da467',
          _type: 'block',
          children: [
            {
              _key: '3be49c5fcba70',
              _type: 'span',
              marks: [],
              text: "You're under contract on a house, but you've run into some snags along the way. Can you walk away from the deal? | Own Up"
            }
          ],
          markDefs: [],
          style: 'normal'
        }
      ],
      categories: [
        {
          title: 'First Home Loan'
        }
      ],
      mainImage: {
        asset: {
          gatsbyImageData: {
            images: {
              sources: [],
              fallback: {
                src: 'https://cdn.sanity.io/images/paxrx6m7/staging/730187180f264fef7ee17a600d3325bb6c316aa6-1120x653.jpg?w=410&h=239&auto=format',
                srcSet:
                  'https://cdn.sanity.io/images/paxrx6m7/staging/730187180f264fef7ee17a600d3325bb6c316aa6-1120x653.jpg?rect=2,0,1117,653&w=320&h=187&auto=format 320w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/730187180f264fef7ee17a600d3325bb6c316aa6-1120x653.jpg?w=410&h=239&auto=format 410w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/730187180f264fef7ee17a600d3325bb6c316aa6-1120x653.jpg?rect=0,1,1120,652&w=654&h=381&auto=format 654w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/730187180f264fef7ee17a600d3325bb6c316aa6-1120x653.jpg?rect=1,0,1119,653&w=768&h=448&auto=format 768w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/730187180f264fef7ee17a600d3325bb6c316aa6-1120x653.jpg?w=1024&h=597&auto=format 1024w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/730187180f264fef7ee17a600d3325bb6c316aa6-1120x653.jpg?w=1120&h=653&auto=format 1120w',
                sizes: '(min-width: 410px) 410px, 100vw'
              }
            },
            layout: 'constrained',
            width: 410,
            height: 239,
            placeholder: {
              fallback:
                'data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAMABQDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAABwD/xAAhEAACAQMFAAMAAAAAAAAAAAABAgMABAUGBxEhMRNBsf/EABYBAQEBAAAAAAAAAAAAAAAAAAIBA//EABgRAQEAAwAAAAAAAAAAAAAAAAEAAhIi/9oADAMBAAIRAxEAPwAE00EOoMeJVDIZ1DA+Ec0+b743CWG3lscXYQW9y9wpdkXgnqgDAHjNWJH1Mv7S5vPfzzadhgcr8YkUjrvypkmwSDlhSqqqtIX/2Q=='
            }
          }
        },
        alt: 'An open door in a dark room leading to a lit hallway'
      }
    },
    {
      slug: {
        current: 'first-time-homebuyer-checklist'
      },
      title: 'First Time Homebuyer Checklist',
      description:
        'Looking for the First-time Home Buyer Checklist? Read More to Find Everything you Need to Know.',
      _rawExcerpt: [
        {
          _key: '97ace94b6c01',
          _type: 'block',
          children: [
            {
              _key: '97ace94b6c010',
              _type: 'span',
              marks: [],
              text: 'We’ve all heard the famous saying “practice makes perfect.” For things like learning the piano or throwing a baseball, it’s entirely true…'
            }
          ],
          markDefs: [],
          style: 'normal'
        }
      ],
      categories: [
        {
          title: 'First Home Loan'
        }
      ],
      mainImage: {
        asset: {
          gatsbyImageData: {
            images: {
              sources: [],
              fallback: {
                src: 'https://cdn.sanity.io/images/paxrx6m7/staging/fdacd29e9de14702fb2b6bc119ad5e3d9a5daa37-1120x652.jpg?rect=1,0,1118,652&w=410&h=239&auto=format',
                srcSet:
                  'https://cdn.sanity.io/images/paxrx6m7/staging/fdacd29e9de14702fb2b6bc119ad5e3d9a5daa37-1120x652.jpg?rect=2,0,1116,652&w=320&h=187&auto=format 320w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/fdacd29e9de14702fb2b6bc119ad5e3d9a5daa37-1120x652.jpg?rect=1,0,1118,652&w=410&h=239&auto=format 410w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/fdacd29e9de14702fb2b6bc119ad5e3d9a5daa37-1120x652.jpg?rect=1,0,1119,652&w=654&h=381&auto=format 654w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/fdacd29e9de14702fb2b6bc119ad5e3d9a5daa37-1120x652.jpg?rect=1,0,1118,652&w=768&h=448&auto=format 768w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/fdacd29e9de14702fb2b6bc119ad5e3d9a5daa37-1120x652.jpg?rect=1,0,1118,652&w=1024&h=597&auto=format 1024w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/fdacd29e9de14702fb2b6bc119ad5e3d9a5daa37-1120x652.jpg?rect=1,0,1118,652&w=1120&h=653&auto=format 1120w',
                sizes: '(min-width: 410px) 410px, 100vw'
              }
            },
            layout: 'constrained',
            width: 410,
            height: 239,
            placeholder: {
              fallback:
                'data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAMABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMFBgj/xAAhEAACAgIBBAMAAAAAAAAAAAABAwIEABEFBhIhQQcTMf/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERAv/aAAwDAQACEQMRAD8A6Mt8tSrNC2sPeTrQGziL3IfXYjXrrE2yGxs6GJuCMeWmwQh3/ncR5yB8jPnxqqdmmQt0zoy96yN6aVHKpparWsTGVivKDfYj5GGTOmrr3cOibJ7kR5OGUnUEP//Z'
            }
          }
        },
        alt: 'a piggy bank with $10 and $1 bills surrounding it'
      }
    },
    {
      slug: {
        current: 'what-does-pending-contract-mean'
      },
      title: 'From Sale to Sold: Your Guide to the “Pending” Status in Real Estate',
      description:
        'What does it mean when an active listing moves into the pending phase? This article will break down the details about pending home sales and how they differ from other listing labels.',
      _rawExcerpt: [
        {
          _key: '738a9934daac',
          _type: 'block',
          children: [
            {
              _key: 'd4a97b574bb30',
              _type: 'span',
              marks: [],
              text: 'What does it mean when an active listing moves into the pending phase? This article will break down the details about pending home sales and how they differ from other listing labels. | Own Up'
            }
          ],
          markDefs: [],
          style: 'normal'
        },
        {
          _key: 'd0f6dd4fc295',
          _type: 'block',
          children: [
            {
              _key: '93ec01e950fb0',
              _type: 'span',
              marks: [],
              text: '\n'
            }
          ],
          markDefs: [],
          style: 'normal'
        }
      ],
      categories: [
        {
          title: 'First Home Loan'
        }
      ],
      mainImage: {
        asset: {
          gatsbyImageData: {
            images: {
              sources: [],
              fallback: {
                src: 'https://cdn.sanity.io/images/paxrx6m7/staging/823c66290ef66e19678c575f366b25863d13070b-839x450.png?w=410&h=220&auto=format',
                srcSet:
                  'https://cdn.sanity.io/images/paxrx6m7/staging/823c66290ef66e19678c575f366b25863d13070b-839x450.png?rect=2,0,837,450&w=320&h=172&auto=format 320w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/823c66290ef66e19678c575f366b25863d13070b-839x450.png?w=410&h=220&auto=format 410w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/823c66290ef66e19678c575f366b25863d13070b-839x450.png?rect=1,0,838,450&w=654&h=351&auto=format 654w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/823c66290ef66e19678c575f366b25863d13070b-839x450.png?w=768&h=412&auto=format 768w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/823c66290ef66e19678c575f366b25863d13070b-839x450.png?w=839&h=450&auto=format 839w',
                sizes: '(min-width: 410px) 410px, 100vw'
              }
            },
            layout: 'constrained',
            width: 410,
            height: 220,
            placeholder: {
              fallback:
                'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACiklEQVQokRXMSU8TUQAA4PkvxKscjMabmHAwhpOaoIgBQUQgFU0ICXBQ2QREwyZLTVk0bC0UAw2NWtZWSkstNHSh0JnOmzdv3nsz82am0HA2JN/54wK+9Yh/I7bri3lXootTOwMd7prS5bL7/+wDmpDWiQTPjo/DW56FSdeMPbD16yC8FwwG9vb3gqEQF48E09HQoXvO2/J6vvKB41Hx6N3C6eIbge5WmopdGFRDgpCKxkI70f2dcGjXu7W+5HW71pacP10c5NPyaSLinJktKxm8de3LzYLBmwWTRYWbLQ3ygT9v0nMDq0ggkIdCOri3Ob3o6Jno7xrt6xr7xKlQJICPeFfma5+O37k+UlQ4dO/2VHnJ5ud32YNdA4umChmBhqqIZ0mf1/11uLep9U19U/2r5gZOVyQVgXhwZ62//Udd2eDzhx3Vj4fbGn3OaTF5yIhkqnJOJ0xFiaOQe8HR29lma3hRU/2sovIJZ1BkUIT4k/Afz/zwx54W24dmm2OoL7L7WwOZC0bzOZa3mAKFoN83NzPe3d7WaKutqSqvKC/lLI3kdGKpCjg53l51uuyDnu/2qM9Dkod5JFwycnlu5HMMipm//s1l19zE2FB35/umt7a6l1WcpZKcTi9UbIGMEjuQwn5yHDH5k3M5e07g1WvqhqErihxPxLf9/jWPx7kw+21spL+nk2MEMYoNLJswa4q8IYkMQR0jihUFY4SJhAnAVMJEgCjFZ+PJ1FE0EtjeWF91cwRJUJIAABAAIEoAyBJSoIKBjEVEIKYyoQqlVFPVK1SlmCAJ8KfpRIxToJjJCOkzgc8CQYQAIgUTTVN1XWOGblksZ7GcqedMzWIq04hOFRXLGIowe/YfBN/ywPIdnQQAAAAASUVORK5CYII='
            }
          }
        },
        alt: 'A stamp on paper that says, "Approved: Mortgage Application Form."'
      }
    },
    {
      slug: {
        current: 'what-are-contingencies-in-homebuying-process'
      },
      title: 'What are Contingencies and How Do They Affect Your Home Purchase?',
      description:
        'Contingencies can serve as an insurance policy during the homebuying process – for both the buyer and the seller. Here’s an in-depth look at how they can support your purchase process.',
      _rawExcerpt: [
        {
          _key: '1852fd98d62c',
          _type: 'block',
          children: [
            {
              _key: '9241d556518f0',
              _type: 'span',
              marks: [],
              text: 'Contingencies can serve as an insurance policy during the homebuying process – for both the buyer and the seller. Here’s an in-depth look at how they can support your purchase process. | OwnUp'
            }
          ],
          markDefs: [],
          style: 'normal'
        },
        {
          _key: '44f18f3205f7',
          _type: 'block',
          children: [
            {
              _key: '20ea7dbd32850',
              _type: 'span',
              marks: [],
              text: '\n'
            }
          ],
          markDefs: [],
          style: 'normal'
        }
      ],
      categories: [
        {
          title: 'First Home Loan'
        }
      ],
      mainImage: {
        asset: {
          gatsbyImageData: {
            images: {
              sources: [],
              fallback: {
                src: 'https://cdn.sanity.io/images/paxrx6m7/staging/083a5703d210354863a42e6fc894bad6d50817c7-718x669.jpg?w=410&h=382&auto=format',
                srcSet:
                  'https://cdn.sanity.io/images/paxrx6m7/staging/083a5703d210354863a42e6fc894bad6d50817c7-718x669.jpg?w=320&h=298&auto=format 320w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/083a5703d210354863a42e6fc894bad6d50817c7-718x669.jpg?w=410&h=382&auto=format 410w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/083a5703d210354863a42e6fc894bad6d50817c7-718x669.jpg?w=654&h=609&auto=format 654w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/083a5703d210354863a42e6fc894bad6d50817c7-718x669.jpg?w=718&h=669&auto=format 718w',
                sizes: '(min-width: 410px) 410px, 100vw'
              }
            },
            layout: 'constrained',
            width: 410,
            height: 382,
            placeholder: {
              fallback:
                'data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAATABQDASIAAhEBAxEB/8QAGQABAAIDAAAAAAAAAAAAAAAAAAUGAQcI/8QAIhAAAgIBAwUBAQAAAAAAAAAAAQIAAwQFERIGEyFBcUJh/8QAFwEBAQEBAAAAAAAAAAAAAAAABAEAAv/EABsRAAICAwEAAAAAAAAAAAAAAAABAhEEE1Eh/9oADAMBAAIRAxEAPwDoHW0tGl5JxG7d/A8WHqQnQQvdy99z3+CAzncyX1zNpxaeOQ4VHBB39yK0DMw6gi4FqFWbyFO8mtuSdlU0oONFxZFU7KNh/ImWfyPkTHJSOvKKrsVDYgYym6JTXi6rjpjr21ZxuF9xELlOlFrozF9tPhuV/wA/BERFBD//2Q=='
            }
          }
        },
        alt: 'A man standing in front of a house with a clipboard who could be an inspector or appraiser'
      }
    },
    {
      slug: {
        current: 'down-payment-assistance'
      },
      title:
        'Breaking Barriers: Accessing Down Payment Assistance Programs for First-Time Homebuyers',
      description:
        'The down payment amount you need may be more accessible than you think with the help of local government initiatives aimed at encouraging homeownership.',
      _rawExcerpt: [
        {
          _key: '5ed818b3cfb0',
          _type: 'block',
          children: [
            {
              _key: 'd5ba9c42521f0',
              _type: 'span',
              marks: [],
              text: 'The down payment amount you need may be more accessible than you think with the help of local government initiatives aimed at encouraging homeownership. | Own Up'
            }
          ],
          markDefs: [],
          style: 'normal'
        }
      ],
      categories: [
        {
          title: 'First Home Loan'
        }
      ],
      mainImage: {
        asset: {
          gatsbyImageData: {
            images: {
              sources: [],
              fallback: {
                src: 'https://cdn.sanity.io/images/paxrx6m7/staging/96ec1cc3c6d88aeed02a73b47f592d487aaede92-1120x652.jpg?rect=1,0,1118,652&w=410&h=239&auto=format',
                srcSet:
                  'https://cdn.sanity.io/images/paxrx6m7/staging/96ec1cc3c6d88aeed02a73b47f592d487aaede92-1120x652.jpg?rect=2,0,1116,652&w=320&h=187&auto=format 320w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/96ec1cc3c6d88aeed02a73b47f592d487aaede92-1120x652.jpg?rect=1,0,1118,652&w=410&h=239&auto=format 410w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/96ec1cc3c6d88aeed02a73b47f592d487aaede92-1120x652.jpg?rect=1,0,1119,652&w=654&h=381&auto=format 654w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/96ec1cc3c6d88aeed02a73b47f592d487aaede92-1120x652.jpg?rect=1,0,1118,652&w=768&h=448&auto=format 768w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/96ec1cc3c6d88aeed02a73b47f592d487aaede92-1120x652.jpg?rect=1,0,1118,652&w=1024&h=597&auto=format 1024w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/96ec1cc3c6d88aeed02a73b47f592d487aaede92-1120x652.jpg?rect=1,0,1118,652&w=1120&h=653&auto=format 1120w',
                sizes: '(min-width: 410px) 410px, 100vw'
              }
            },
            layout: 'constrained',
            width: 410,
            height: 239,
            placeholder: {
              fallback:
                'data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAMABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAUGA//EACAQAAICAwACAwEAAAAAAAAAAAECAwUABBESIQYxUWH/xAAWAQEBAQAAAAAAAAAAAAAAAAADAQT/xAAcEQACAQUBAAAAAAAAAAAAAAAAAQIDERIhMRP/2gAMAwEAAhEDEQA/AE+lYV0FijRhBIw8ZOnqkfnMsvkVE9TWJbPsQxxSdCqi/wA9ZO2WnrSVCKsEcfmw6UXhyUv7WwjddY7s8kBYL4SN0AZZ0rLQHvj1G+ssE0Ql2GiLuSfZ+h3DEG5M6bUqqeAH0Bhh4Se0zM60E+H/2Q=='
            }
          }
        },
        alt: 'A couple with arms wrapped around each other and staring at a house'
      }
    },
    {
      slug: {
        current: 'mortgage-application-document-checklist'
      },
      title: 'Preparation is Key: Your Ultimate Checklist of Mortgage Application Documents',
      description:
        'From pre-approval to the loan application, you’ll become intimately familiar with paperwork during the mortgage process.',
      _rawExcerpt: [
        {
          _key: '0fddf8d43132',
          _type: 'block',
          children: [
            {
              _key: '0931429f06af0',
              _type: 'span',
              marks: [],
              text: 'From pre-approval to the loan application, you’ll become intimately familiar with paperwork during the mortgage process. | Own Up'
            }
          ],
          markDefs: [],
          style: 'normal'
        }
      ],
      categories: [
        {
          title: 'First Home Loan'
        }
      ],
      mainImage: {
        asset: {
          gatsbyImageData: {
            images: {
              sources: [],
              fallback: {
                src: 'https://cdn.sanity.io/images/paxrx6m7/staging/1d508b5c83e52da0dbd48777abdcc7a8248c3cba-1120x652.jpg?rect=1,0,1118,652&w=410&h=239&auto=format',
                srcSet:
                  'https://cdn.sanity.io/images/paxrx6m7/staging/1d508b5c83e52da0dbd48777abdcc7a8248c3cba-1120x652.jpg?rect=2,0,1116,652&w=320&h=187&auto=format 320w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/1d508b5c83e52da0dbd48777abdcc7a8248c3cba-1120x652.jpg?rect=1,0,1118,652&w=410&h=239&auto=format 410w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/1d508b5c83e52da0dbd48777abdcc7a8248c3cba-1120x652.jpg?rect=1,0,1119,652&w=654&h=381&auto=format 654w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/1d508b5c83e52da0dbd48777abdcc7a8248c3cba-1120x652.jpg?rect=1,0,1118,652&w=768&h=448&auto=format 768w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/1d508b5c83e52da0dbd48777abdcc7a8248c3cba-1120x652.jpg?rect=1,0,1118,652&w=1024&h=597&auto=format 1024w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/1d508b5c83e52da0dbd48777abdcc7a8248c3cba-1120x652.jpg?rect=1,0,1118,652&w=1120&h=653&auto=format 1120w',
                sizes: '(min-width: 410px) 410px, 100vw'
              }
            },
            layout: 'constrained',
            width: 410,
            height: 239,
            placeholder: {
              fallback:
                'data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAMABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABwAF/8QAJRAAAQQBAwIHAAAAAAAAAAAAAQACAwQRBQYhBzESExQiM5Gh/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAX/xAAaEQEAAgMBAAAAAAAAAAAAAAABABEDEiFR/9oADAMBAAIRAxEAPwA1gghFiEOYJIM/GDgkpD0yhBNSEtWrfgl7YY7KMKzjYquMhOWngjuk/pVuPUfE2h5jPTsBI9vP2pS0dlOrbJlXttTyWXPcy/k88x5Ul9+qWycmT8CkMjGp5P/Z'
            }
          }
        },
        alt: 'A very large stack of papers in folder files'
      }
    },
    {
      slug: {
        current: 'steps-for-first-time'
      },
      title: '10 Essential Steps for First-Time Home Buyers',
      description:
        'The homebuying process can seem daunting, but don’t worry, we’ll take you through it step by step.',
      _rawExcerpt: [
        {
          _key: '70dfc74075e9',
          _type: 'block',
          children: [
            {
              _key: '3d9406d6c7500',
              _type: 'span',
              marks: [],
              text: 'The homebuying process can seem daunting, but don’t worry, we’ll take you through it step by step.'
            }
          ],
          markDefs: [],
          style: 'normal'
        },
        {
          _key: 'd19b245fa6fc_deduped_1',
          _type: 'block',
          children: [
            {
              _key: 'f9f8562ffb30',
              _type: 'span',
              marks: [],
              text: ''
            }
          ],
          markDefs: [],
          style: 'normal'
        }
      ],
      categories: [
        {
          title: 'First Home Loan'
        }
      ],
      mainImage: {
        asset: {
          gatsbyImageData: {
            images: {
              sources: [],
              fallback: {
                src: 'https://cdn.sanity.io/images/paxrx6m7/staging/8d533a079e5b1e5622a2de10c289ffeb18a00ebc-1120x745.jpg?rect=1,0,1119,745&w=410&h=273&auto=format',
                srcSet:
                  'https://cdn.sanity.io/images/paxrx6m7/staging/8d533a079e5b1e5622a2de10c289ffeb18a00ebc-1120x745.jpg?rect=1,0,1119,745&w=320&h=213&auto=format 320w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/8d533a079e5b1e5622a2de10c289ffeb18a00ebc-1120x745.jpg?rect=1,0,1119,745&w=410&h=273&auto=format 410w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/8d533a079e5b1e5622a2de10c289ffeb18a00ebc-1120x745.jpg?w=654&h=435&auto=format 654w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/8d533a079e5b1e5622a2de10c289ffeb18a00ebc-1120x745.jpg?w=768&h=511&auto=format 768w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/8d533a079e5b1e5622a2de10c289ffeb18a00ebc-1120x745.jpg?rect=1,0,1119,745&w=1024&h=682&auto=format 1024w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/8d533a079e5b1e5622a2de10c289ffeb18a00ebc-1120x745.jpg?rect=1,0,1118,745&w=1120&h=746&auto=format 1120w',
                sizes: '(min-width: 410px) 410px, 100vw'
              }
            },
            layout: 'constrained',
            width: 410,
            height: 273,
            placeholder: {
              fallback:
                'data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAYCBQf/xAAgEAABBAIDAAMAAAAAAAAAAAABAgMEBQARBhIhBzNh/8QAFQEBAQAAAAAAAAAAAAAAAAAAAwT/xAAgEQABAgUFAAAAAAAAAAAAAAACAAEDBBEUIRITIjFh/9oADAMBAAIRAxEAPwB0qeG1cRQL6VvN72kKPgyPyTVQXKEdK4yHkfUlo9SMZorpdipKgPRlfJS3L7RpLYWgDYO9EYO6TlV3StCBhpTCy+h4HSTKxp+2acYmL2VoWvRGGKXLORTWr6S0wsoabV0SkknQH7hlFvHLOpTXcmHFw68X/9k='
            }
          }
        },
        alt: 'a hand holding a pen and writing off a checklist marked by boxes'
      }
    },
    {
      slug: {
        current: 'the-10-best-home-mortgage-brokers-in-boston-ma-for-2022'
      },
      title: 'The 10 “Best” Home Mortgage Brokers in Boston, MA for 2022',
      description:
        'With so many Massachusetts mortgage lenders, and 17,000+ options across the country, here are the top 10 mortgage lenders by volume in Massachusetts.',
      _rawExcerpt: [
        {
          _key: 'a60dde59d6a5',
          _type: 'block',
          children: [
            {
              _key: 'a60dde59d6a50',
              _type: 'span',
              marks: [],
              text: 'Whether you’re new to the homebuying experience or you’ve been through the process a few times, getting a mortgage can be intimidating. It’s…'
            }
          ],
          markDefs: [],
          style: 'normal'
        }
      ],
      categories: [
        {
          title: 'First Home Loan'
        }
      ],
      mainImage: {
        asset: {
          gatsbyImageData: {
            images: {
              sources: [],
              fallback: {
                src: 'https://cdn.sanity.io/images/paxrx6m7/staging/d55a99039fc035cb9e0a72fc2a7882ed0ffa26fa-1120x652.jpg?rect=1,0,1118,652&w=410&h=239&auto=format',
                srcSet:
                  'https://cdn.sanity.io/images/paxrx6m7/staging/d55a99039fc035cb9e0a72fc2a7882ed0ffa26fa-1120x652.jpg?rect=2,0,1116,652&w=320&h=187&auto=format 320w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/d55a99039fc035cb9e0a72fc2a7882ed0ffa26fa-1120x652.jpg?rect=1,0,1118,652&w=410&h=239&auto=format 410w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/d55a99039fc035cb9e0a72fc2a7882ed0ffa26fa-1120x652.jpg?rect=1,0,1119,652&w=654&h=381&auto=format 654w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/d55a99039fc035cb9e0a72fc2a7882ed0ffa26fa-1120x652.jpg?rect=1,0,1118,652&w=768&h=448&auto=format 768w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/d55a99039fc035cb9e0a72fc2a7882ed0ffa26fa-1120x652.jpg?rect=1,0,1118,652&w=1024&h=597&auto=format 1024w,\nhttps://cdn.sanity.io/images/paxrx6m7/staging/d55a99039fc035cb9e0a72fc2a7882ed0ffa26fa-1120x652.jpg?rect=1,0,1118,652&w=1120&h=653&auto=format 1120w',
                sizes: '(min-width: 410px) 410px, 100vw'
              }
            },
            layout: 'constrained',
            width: 410,
            height: 239,
            placeholder: {
              fallback:
                'data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAMABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEB//EACIQAAEDBAICAwAAAAAAAAAAAAEAAgMEESExEkEFFCMyUf/EABQBAQAAAAAAAAAAAAAAAAAAAAH/xAAVEQEBAAAAAAAAAAAAAAAAAAAAYf/aAAwDAQACEQMRAD8A2w1svsSsb9WHvtTVPkC6F7mHOuJ6T6qMNcONxc5so56eP8OM72g0iGdrWn5Rk32hKMTCSeIznSEh/9k='
            }
          }
        },
        alt: 'Main Image'
      }
    }
  ]
};
