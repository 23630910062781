import React from 'react';
import { PostCard } from './post-card';
import { Post } from './types';

interface TopPosts {
  pinnedPosts: any[];
  lastPosts: any[];
}
const TOP_POST_LENGTH = 3;
const filterLastPosts = (pinnedPosts: any[], lastPosts: any[]): Post[] => {
  const pinnedTitles = new Set(pinnedPosts.map((post) => post.title));
  return lastPosts.filter((post) => !pinnedTitles.has(post.title));
};
export const TopPosts: React.FC<TopPosts> = ({ pinnedPosts, lastPosts }) => {
  let postToDisplay = [...pinnedPosts];
  if (pinnedPosts.length < TOP_POST_LENGTH) {
    postToDisplay = [
      ...postToDisplay,
      ...filterLastPosts(pinnedPosts, lastPosts).slice(0, TOP_POST_LENGTH - pinnedPosts.length)
    ];
  }
  return (
    <>
      {postToDisplay.map((post) => (
        <PostCard
          key={post.title}
          title={post.title}
          description={post.description}
          href={post.slug.current || '#'}
          img={post.mainImage.asset.gatsbyImageData}
          categories={post.categories.map((postCategory: Post) => postCategory.title)}
          cardStyles={{ boxShadow: 'none' }}
        />
      ))}
    </>
  );
};
