import { OwnUpGridWrapper, OwnUpThemeProvider } from '@rategravity/own-up-component-library';
import React from 'react';
import Check from '../../../images/inline-svg/lenders/onboarding/check.inline.svg';
import Crm from '../../../images/inline-svg/lenders/onboarding/crm.inline.svg';
import Time from '../../../images/inline-svg/lenders/onboarding/time.inline.svg';
import VideoCall from '../../../images/inline-svg/lenders/onboarding/video-call.inline.svg';
import {
  BulleMiddle,
  BulleRight,
  BulletLeft,
  Content,
  DottedLine,
  Headline,
  ImageWrapper,
  OnboardingHeadline,
  OnboardingItem,
  OnboardingItems,
  OnboardingSubHeadline,
  Section
} from './elements';

export const LenderOnboarding = () => {
  return (
    <OwnUpThemeProvider>
      <Section aria-label="LenderOnboarding">
        <OwnUpGridWrapper>
          <Content>
            <Headline>Streamlined Two-Week Onboarding</Headline>
            <OnboardingItems>
              <DottedLine />
              <BulletLeft />
              <BulleMiddle />
              <BulleRight />
              <OnboardingItem>
                <ImageWrapper>
                  <VideoCall />
                </ImageWrapper>
                <div>
                  <OnboardingHeadline>Initiation</OnboardingHeadline>
                  <OnboardingSubHeadline>
                    Reach out to lenders@ownup.com to schedule an intro call or demo
                  </OnboardingSubHeadline>
                </div>
              </OnboardingItem>
              <OnboardingItem>
                <ImageWrapper>
                  <Time />
                </ImageWrapper>
                <div>
                  <OnboardingHeadline>Contracting & Vendor Management</OnboardingHeadline>
                  <OnboardingSubHeadline>
                    Vendor due diligence and contract execution between you and OwnUp. This step
                    takes about a week
                  </OnboardingSubHeadline>
                </div>
              </OnboardingItem>
              <OnboardingItem>
                <ImageWrapper>
                  <Crm />
                </ImageWrapper>
                <div>
                  <OnboardingHeadline>Integration</OnboardingHeadline>
                  <OnboardingSubHeadline>
                    Integrate to both your CRM and pricing engine for optimal performance. This step
                    takes about a week
                  </OnboardingSubHeadline>
                </div>
              </OnboardingItem>
              <OnboardingItem>
                <ImageWrapper>
                  <Check />
                </ImageWrapper>
                <div>
                  <OnboardingHeadline>Launch</OnboardingHeadline>
                  <OnboardingSubHeadline>
                    Schedule the launch and set up bi-weekly check-ins to ensure performance
                    achievement
                  </OnboardingSubHeadline>
                </div>
              </OnboardingItem>
            </OnboardingItems>
          </Content>
        </OwnUpGridWrapper>
      </Section>
    </OwnUpThemeProvider>
  );
};
