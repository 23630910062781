import { IconLink, PropsWithTheme } from '@rategravity/own-up-component-library';
import { ArrowRightIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/arrow-right';
import React from 'react';
import styled from 'styled-components';

const IconLinkWrapper = styled.div`
  padding-top: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(1)}px`};
`;

export const ReadMoreLink = ({ link, title }: { link: string; title: string }) => (
  <IconLinkWrapper>
    <IconLink
      icon={<ArrowRightIcon />}
      link={`/learn/${link}/`}
      iconPlacement="right"
      aria-label={title}
    >
      Read more
    </IconLink>
  </IconLinkWrapper>
);
