import { OwnUpGridWrapper, OwnUpThemeProvider } from '@rategravity/own-up-component-library';
import React from 'react';

import { SuiteOne, SuiteTwo } from '../../static-images/lenders';

import {
  Content,
  LeftImageSuiteDescription,
  LeftSuiteImage,
  Section,
  SectionTitle,
  SuiteDescription,
  SuiteImage,
  SuiteWrapper
} from './elements';

export const ProductSuite = () => {
  return (
    <OwnUpThemeProvider>
      <Section aria-label="product-suite">
        <OwnUpGridWrapper>
          <Content>
            <SectionTitle>Product Suite</SectionTitle>
            <SuiteWrapper>
              <SuiteImage>
                <SuiteOne />
              </SuiteImage>
              <SuiteDescription>
                <h3>Concierge Leads</h3>
                <h4>Post Consultation</h4>
                <p>
                  Customers speak with Own Up’s Home Advisor team and then opt into a specific
                  lender to get the Pre-Approval issued by the lender.
                </p>
              </SuiteDescription>
            </SuiteWrapper>
            <SuiteWrapper>
              <LeftImageSuiteDescription>
                <h3>Web Leads</h3>
                <h4>Digitally Enhanced</h4>
                <p>
                  Customers complete our onboarding experience and connect directly to lenders based
                  on their loan scenario and AI powered predictive conversion.
                </p>
              </LeftImageSuiteDescription>
              <LeftSuiteImage>
                <SuiteTwo />
              </LeftSuiteImage>
            </SuiteWrapper>
          </Content>
        </OwnUpGridWrapper>
      </Section>
    </OwnUpThemeProvider>
  );
};
