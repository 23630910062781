import { OwnUpGridWrapper, OwnUpThemeProvider } from '@rategravity/own-up-component-library';
import React from 'react';
import { LendersHeroImage } from '../../static-images/lenders';
import {
  ButtonWrapper,
  Content,
  CTAArea,
  CTAHeadline,
  CTASubHeadline,
  ImageWrapper,
  LendersEmail,
  Section
} from './elements';

const PureHero = () => {
  return (
    <Content>
      <CTAArea>
        <CTAHeadline>Increase loan volume and improve profitability</CTAHeadline>
        <CTASubHeadline>
          An exclusive real-time customer acquisition platform for mortgage lenders
        </CTASubHeadline>
        <ButtonWrapper>
          Contact us now
          <LendersEmail href="mailto:lenders@ownup.com">lenders@ownup.com</LendersEmail>
        </ButtonWrapper>
      </CTAArea>

      <ImageWrapper>
        <LendersHeroImage />
      </ImageWrapper>
    </Content>
  );
};

export const LendersHero = () => {
  return (
    <OwnUpThemeProvider>
      <Section aria-label="lenders-hero">
        <OwnUpGridWrapper>
          <PureHero />
        </OwnUpGridWrapper>
      </Section>
    </OwnUpThemeProvider>
  );
};
