import {
  OwnUpGridContainer,
  OwnUpGridWrapper,
  OwnUpThemeProvider
} from '@rategravity/own-up-component-library';
import React from 'react';

import { TopPosts } from '../top-posts';
import { Content, SectionTitle } from './elements';

import { mockData } from './mock-data';

export const LendersResources = () => {
  return (
    <OwnUpThemeProvider>
      <section aria-label="lenders-resources">
        <OwnUpGridWrapper>
          <Content>
            <SectionTitle>Insights and Resources</SectionTitle>
            <OwnUpGridWrapper>
              <OwnUpGridContainer variant="legacy">
                <TopPosts pinnedPosts={mockData.pinnedPosts} lastPosts={mockData.lastPosts} />
              </OwnUpGridContainer>
            </OwnUpGridWrapper>
          </Content>
        </OwnUpGridWrapper>
      </section>
    </OwnUpThemeProvider>
  );
};
