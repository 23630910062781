import { OwnUpGridWrapper, OwnUpThemeProvider } from '@rategravity/own-up-component-library';
import { ArrowRightIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/arrow-right';
import { Link } from 'gatsby';
import React from 'react';
import { FaqSection } from '../../faq';

import { CTAWrapper, IconLinkCentered, NewBenefitsSection } from './elements';
import faqData from './mock-data.json';

export const FAQs = () => {
  return (
    <OwnUpThemeProvider>
      <NewBenefitsSection aria-label="FAQa">
        <OwnUpGridWrapper>
          <FaqSection faqData={faqData} title={'Learn more about how to get started - FAQs'} />
          <CTAWrapper>
            <IconLinkCentered
              size="large"
              icon={ArrowRightIcon}
              iconPlacement="right"
              link="/methodology"
              component={Link}
              to="/methodology"
            >
              More FAQs
            </IconLinkCentered>
          </CTAWrapper>
        </OwnUpGridWrapper>
      </NewBenefitsSection>
    </OwnUpThemeProvider>
  );
};
