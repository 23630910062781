import {
  OwnUpBody,
  OwnUpGridItem,
  OwnUpSmallSubheadlineMedium
} from '@rategravity/own-up-component-library';
import { IGatsbyImageData } from 'gatsby-plugin-image';

import React from 'react';

import { CategoryLinks } from './category-links';
import { Card, ContentWrapper, StyledImage, Tile } from './components';
import { ReadMoreLink } from './read-more-link';

interface CardStyles {
  textAlign?: string;
  backgroundColor?: string;
  padding?: string;
  boxShadow?: string;
  borderRadius?: string;
}

export interface PostCard {
  title: string;
  description: string;
  href: string;
  img: IGatsbyImageData | null;
  imgAlt?: string;
  categories: string[];
  cardStyles?: CardStyles;
}

export const PostCard = ({
  title,
  description,
  href,
  img,
  imgAlt,
  categories,
  cardStyles = {}
}: PostCard) => (
  <OwnUpGridItem xs={12} md={4}>
    <Card boxShadow={cardStyles.boxShadow}>
      {img && <StyledImage image={img} alt={imgAlt || title} />}
      <Tile backgroundColor={cardStyles.backgroundColor} padding={cardStyles.padding}>
        <ContentWrapper>
          {categories.length > 0 && <CategoryLinks categories={categories} />}
          <OwnUpSmallSubheadlineMedium variant="h3">{title}</OwnUpSmallSubheadlineMedium>
          {description && (
            <OwnUpBody variant="body1">
              {description.length < 180 ? description : description.substring(0, 180) + '...'}
            </OwnUpBody>
          )}
          <ReadMoreLink link={href} title={title} />
        </ContentWrapper>
      </Tile>
    </Card>
  </OwnUpGridItem>
);
