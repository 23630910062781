import { OwnUpGridWrapper, OwnUpThemeProvider } from '@rategravity/own-up-component-library';
import React from 'react';

import Guaranteed from '../../../images/lenders/guaranteed-rate-logo.svg';
import MutualOfOmaha from '../../../images/lenders/mutual-of-omaha.svg';
import NbkcBank from '../../../images/lenders/nbkc-bank.svg';
import NewAmericanFunding from '../../../images/lenders/new-american-funding.svg';
import RocketMortgage from '../../../images/lenders/rocket-mortgage.svg';

import {
  Content,
  LenderLink,
  LendersList,
  LendersListScrollWrapper,
  LendersListWrapper,
  Section
} from './elements';

export const TopLenders = () => {
  return (
    <OwnUpThemeProvider>
      <Section aria-label="mobile-charts">
        <OwnUpGridWrapper>
          <Content style={{ position: 'relative' }}>
            <LendersListWrapper>
              <LendersListScrollWrapper>
                <LendersList>
                  <LenderLink href="https://www.rocketmortgage.com/" target="_blank">
                    <img src={RocketMortgage} alt="RocketMortgage" />
                  </LenderLink>
                  <LenderLink href="https://www.mutualofomaha.com/" target="_blank">
                    <img src={MutualOfOmaha} alt="MutualOfOmaha" />
                  </LenderLink>
                  <LenderLink href="https://www.newamericanfunding.com/" target="_blank">
                    <img src={NewAmericanFunding} alt="NewAmericanFunding" />
                  </LenderLink>
                  <LenderLink href="https://www.nbkc.com/" target="_blank">
                    <img src={NbkcBank} alt="NbkcBank" />
                  </LenderLink>
                  <LenderLink href="https://www.rate.com/" target="_blank">
                    <img src={Guaranteed} alt="Guaranteed" />
                  </LenderLink>
                </LendersList>
              </LendersListScrollWrapper>
            </LendersListWrapper>
          </Content>
        </OwnUpGridWrapper>
      </Section>
    </OwnUpThemeProvider>
  );
};
