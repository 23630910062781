import {
  ownUpDarkTheme,
  OwnUpGridWrapper,
  OwnUpThemeProvider
} from '@rategravity/own-up-component-library';
import React from 'react';

import SearchFormIcon from '../../../images/inline-svg/lenders/search.inline.svg';
import {
  Content,
  FormIconWrapper,
  // FormInput,
  // FromInputsGroup,
  LendersEmail,
  Section,
  SectionTitle
} from './elements';

export const LendersContactForm = () => {
  return (
    <OwnUpThemeProvider theme={ownUpDarkTheme}>
      <Section aria-label="contact-form">
        <OwnUpGridWrapper>
          <Content>
            <FormIconWrapper>
              <SearchFormIcon />
            </FormIconWrapper>
            <SectionTitle>Contact Us</SectionTitle>
            {/* <FromInputsGroup>
              <FormInput label="Your Name" />
              <FormInput label="Phone Number" />
              <FormInput label="Email address" />
              <FormInput label="Company" />
            </FromInputsGroup> */}
            <LendersEmail href="mailto:lenders@ownup.com">lenders@ownup.com</LendersEmail>
          </Content>
        </OwnUpGridWrapper>
      </Section>
    </OwnUpThemeProvider>
  );
};
