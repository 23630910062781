import { OwnUpBodyMedium, PropsWithTheme } from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';

const CategoryLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(1)}px`};
`;

const Link = styled.a`
  color: ${({ theme }: PropsWithTheme<{}>) => theme.underlineLinkColors.linkFontColor};
  text-decoration: none;
`;

export const kebab = (text: string) =>
  text
    .split('')
    .map((letter) => {
      if (/[A-Z]/.test(letter)) {
        return ` ${letter.toLowerCase()}`;
      }
      return letter;
    })
    .join('')
    .trim()
    .replace(/[_\s]+/g, '-');

export const CategoryLinks = ({ categories }: { categories: string[] }) => (
  <CategoryLinksWrapper>
    {categories.map((category, index) => (
      <Link href={`/learn/${kebab(category)}/`} key={index}>
        <OwnUpBodyMedium variant="body1">{category}</OwnUpBodyMedium>
      </Link>
    ))}
  </CategoryLinksWrapper>
);
