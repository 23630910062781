import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export const LendersHeroImage = () => (
  <StaticImage
    src="../../images/lenders-hero.png"
    alt="Lenders Hero"
    placeholder="none"
    quality={60}
    width={1557}
    breakpoints={[312, 400, 511, 767, 1023, 1279]}
    loading="eager"
  />
);

export const SuiteOne = () => (
  <StaticImage
    src="../../images/lenders/suite-1.png"
    alt="two people checking laptop"
    placeholder="none"
    quality={60}
    loading="eager"
  />
);

export const SuiteTwo = () => (
  <StaticImage
    src="../../images/lenders/suite-2.png"
    alt="three people checking laptop"
    placeholder="none"
    quality={60}
    loading="eager"
  />
);
