import { PropsWithTheme, SAGE_100 } from '@rategravity/own-up-component-library';
import styled from 'styled-components';

export const Section = styled.section`
  background-color: white;
`;

export const Content = styled.div`
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4, 0)};
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10, 0)};
  }
`;

export const LenderLink = styled.a`
  border: 1px solid ${SAGE_100};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 224px;
  height: 88px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xl')} {
    width: 237px;
    height: 136px;
  }
`;

export const LendersList = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 16px;
  width: 100%;
  min-width: 1184px;
`;

export const LendersListScrollWrapper = styled.div`
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  padding-left: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(3)}px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('sm')} {
    padding-left: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6)}px;
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xl')} {
    padding-left: 0;
    position: relative;
    left: unset;
    transform: unset;
    width: auto;
  }
`;

export const LendersListWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 88px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xl')} {
    height: 136px;
  }
`;
