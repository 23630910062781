import React from 'react';
import { Layout } from '../components/layout';
import { BenefitsInNumbers } from '../components/lenders/benefits-in-numbers';
import { LendersContactForm } from '../components/lenders/contact-form';
import { FAQs } from '../components/lenders/faqs';
import { LendersHero } from '../components/lenders/hero';
import { LenderOnboarding } from '../components/lenders/lender-onboarding';
import { ProductSuite } from '../components/lenders/product-suite';
import { LendersResources } from '../components/lenders/resources';
import { TopLenders } from '../components/lenders/top';

export const Lenders = () => {
  return (
    <Layout>
      <LendersHero />
      <TopLenders />
      <BenefitsInNumbers />
      <ProductSuite />
      <LenderOnboarding />
      <LendersResources />
      <FAQs />
      <LendersContactForm />
    </Layout>
  );
};

export default Lenders;
